import React, { useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faC } from "@fortawesome/free-solid-svg-icons";
import { faGithub, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { Box, HStack } from "@chakra-ui/react";

const socials = [
  {
    icon: faEnvelope,
    url: "mailto: iamjikkualex@gmail.com",
  },
  {
    icon: faGithub,
    url: "https://github.com/iamjikkualex",
  },
  {
    icon: faLinkedin,
    url: "https://linkedin.com/in/iamjikkualex",
  },
  {
    icon: faInstagram,
    url: "https://instagram.com/iamjikkualex",
  },
  {
    icon: faC,
    url: "https://www.credly.com/users/iamjikkualex/",
  },
];

const Header = () => {
  const headerRef = useRef(null);

  useEffect(() => {
    let previousScrollPosition = window.scrollY;

    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      const currentHeaderElement = headerRef.current;

      if (!currentHeaderElement) return;

      if (previousScrollPosition > currentScrollPosition)
        currentHeaderElement.style.transform = "translateY(0)";
      else
        currentHeaderElement.style.transform = "translateY(-200px)";

      previousScrollPosition = currentScrollPosition;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleClick = (anchor) => () => {
    const id = `${anchor}-section`;
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      translateY={0}
      transitionProperty="transform"
      transitionDuration=".3s"
      transitionTimingFunction="ease-in-out"
      backgroundColor="#18181b"
      ref={headerRef}
    >
      <Box color="white" maxWidth="1280px" margin="0 auto">
        <HStack
          px={16}
          py={4}
          justifyContent="center"
          alignItems="center"
        >
          <nav>
            <HStack spacing={5}>
              {socials.map(({icon, url}) => (
                <a
                  key={url}
                  href={url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon key={url} icon={icon} size="2x" />
                </a>
              ))}
            </HStack>
          </nav>
          <nav>
            <HStack spacing={8}>
              {/* <a href="myresume" onClick={handleClick("myresume")}>
                Résumé
              </a> */}
            </HStack>
          </nav>
        </HStack>
      </Box>
    </Box>
  );
};
export default Header;
