import React from "react";
import { Avatar, Heading, VStack } from "@chakra-ui/react";
import { TypeAnimation } from "react-type-animation";
import FullScreenSection from "./FullScreenSection";

const greeting = "Hello, I am Jikku.";
const welcome = "Welcome to my profile!";

const LandingSection = () => (
  <FullScreenSection
    justifyContent="center"
    alignItems="center"
    isDarkBackground={false}
    backgroundColor="#FFFFFF"
  >
    <VStack spacing={5}>
      <VStack spacing={4}>
        <a href="/">
          <Avatar
            src={require('../images/me.jpg')}
            size="2xl"
            name="Jikku"
          />
        </a>
        <Heading as="h4" size="md" noOfLines={1}>
          <TypeAnimation
              sequence={[
                greeting, 1000,
                welcome, 1000,
              ]}
              repeat={Infinity}
           />
        </Heading>
      </VStack>
    </VStack>
  </FullScreenSection>
);

export default LandingSection;
